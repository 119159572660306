import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ReactComponent as Next } from '../../assets/img/chevron-down.svg';
import { ReactComponent as Prev } from '../../assets/img/chevron-up.svg';
import './style.css';

const VerticalCarousel = ({
	data,
  darkMode,
  setCurrentItem,
  currentItem
}) => {
	const [activeIndex, setActiveIndex] = useState(0);

   // #1 above. Used to determine which items appear above the active item
   const halfwayIndex = Math.ceil(data.length / 2);

   // #2 above. Used to determine the height/spacing of each item
   const itemHeight = 140;
 
   // #3 above. Used to determine at what point an item is moved from the top to the bottom
   const shuffleThreshold = halfwayIndex * itemHeight;
 
   // #4 above. Used to determine which items should be visible. Prevents "ghost" transitions
   const visibleStyleThreshold = shuffleThreshold / 2;

	const handleClick = (direction) => {
    setActiveIndex((prevIndex) => {
      if (direction === 'next') {
				// If we are at the end of the carousel, set the index to 0
        if (prevIndex + 1 > data.length - 1) {
          setCurrentItem(0);
          return 0;
        }
				// Otherwise increment the index by 1
        setCurrentItem(prevIndex + 1);
        return prevIndex + 1;
      }

			// If we are on the first slide and click previous, go to the last slide
      if (prevIndex - 1 < 0) {
        setCurrentItem(data.length - 1);
        return data.length - 1;
      }
			// We are moving backwards in the carousel, decrement index by 1
      setCurrentItem(prevIndex - 1);
      return prevIndex - 1;
    });
  };

  const determinePlacement = (itemIndex) => {
    if (activeIndex === itemIndex) return 0;

    if (itemIndex >= halfwayIndex) {
      // If moving backwards from index 0 to the last item, move the value downwards
      if (activeIndex > (itemIndex - halfwayIndex)) {
        return (itemIndex - activeIndex) * itemHeight;
      } else {
        // Negative value moves upwards towards the top of the list
        return -((data.length + activeIndex) - itemIndex) * itemHeight;
      }
    }

    if (itemIndex > activeIndex) {
      return (itemIndex - activeIndex) * itemHeight;
    }

    if (itemIndex < activeIndex) {
      if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (data.length - (activeIndex - itemIndex)) * itemHeight;
      }
      return -(activeIndex - itemIndex) * itemHeight;
    }
  };
  const handleImageClick = (index) => {
    setActiveIndex(index);
    setCurrentItem(index);
  }
  useEffect(() => {
    setActiveIndex(currentItem)
  }, [currentItem]);

	return (
		<section className="outer-container">
	    <div className="carousel-wrapper">
				<button
          type="button"
          className="carousel-button prev"
          onClick={() => handleClick('prev')}
        >
          <Prev />
        </button>
        <div className="carousel">
          <div className="slides">
            <div className={cn("carousel-inner", {
              dark: darkMode === true,
            })}>
              {data.map((item, i) => (
                <img
                  src={item.content.image}
                  alt={`${item.alias} profile`}
                  onClick={() => handleImageClick(i)}
                  className={
                    cn('carousel-item justify-end rounded-2xl', {
                    active: activeIndex === i,
                    visible: Math.abs(determinePlacement(i)) <= visibleStyleThreshold,
                    darkBg: darkMode === true,
                    lightBg: darkMode === false,
                  })}
                  key={i}
                  style={{ transform: `translateY(${determinePlacement(i)}px)` }}  
                />
              ))}
            </div>
          </div>
        </div>
				<button
          type="button"
          className="carousel-button next"
          onClick={() => handleClick('next')}
        >
          <Next />
        </button>
			</div>
			<div className={cn("content relative flex items-end justify-center rounded-2xl overflow-hidden", 
          {dark: darkMode === true})}>
        <img
          className='object-cover object-top w-full h-full'
					src={data[activeIndex].content.image}
					alt={data[activeIndex].alias}
				 />
      </div>
		</section>
	);
}

VerticalCarousel.propTypes = {
  data: PropTypes.array.isRequired,
  leadingText: PropTypes.string.isRequired,
};

export default VerticalCarousel;