import { useState } from 'react';

const Header = (props) => {

  const [navBar, setnavBar] = useState("translate-x-full");

  return (
    <>
      <div className="relative z-50 w-full transition bg-transparent border-b border-main border-opacity-[0.15]">
        <div className="mainCont flex items-center justify-between gap-5 py-4">
          <a href="#" className="flex w-fit"><img src="/images/logo-light.svg" alt="logo" className="w-[120px] dark:hidden" /> <img src="/images/logo-dark.svg" alt="logo" className="w-[120px] hidden dark:flex" /></a>
          <div className={`flex items-start lg4:items-center flex-col lg4:flex-row gap-10 text-[15px] lg4:gap-7 fixed top-0 right-0 lg4:relative lg4:top-auto lg4:left-auto px-4 pt-16 pb-5 lg4:px-0 lg4:pb-0 lg4:pt-0 bg-white dark:bg-[#0F1016] dark:lg4:bg-transparent lg4:bg-transparent h-screen lg4:h-auto max-h-screen overflow-y-auto border-l-none sm2:border-l-[3px] border-main lg4:border-none w-full sm2:w-[300px] lg4:w-auto z-[9999999] transition lg4:transition-none lg4:translate-x-0 ${navBar}`}>
            <a href="#" onClick={()=> {setnavBar("translate-x-full")}} className="">Home</a>
            <a href="#services" onClick={()=> {setnavBar("translate-x-full")}} className="">Services</a>
            <a href="https://info.soroswap.finance" onClick={()=> {setnavBar("translate-x-full")}} className="" target='_blank'>Explore</a>
            <a href="#benefits" onClick={()=> {setnavBar("translate-x-full")}} className="">Benefits</a>
            <a href="#community" onClick={()=> {setnavBar("translate-x-full")}} className="">Community</a>
            <a href="#about-us" onClick={()=> {setnavBar("translate-x-full")}} className="">About US</a>
            <a href="#faq" onClick={()=> {setnavBar("translate-x-full")}} className="">FAQ</a>
            {/* <a href="https://app.soroswap.finance" onClick={()=> {setnavBar("translate-x-full")}} className="bg-main2 transition hover:bg-main hover:text-white rounded-[10px] text-[#E0E0E0] px-4 py-[10px]">Launch App</a> */}
            <button onClick={() => {
                props.setdarkMode(!props.darkMode)
                setnavBar("translate-x-full")
               }} className="lg4:-ml-2 -mt-5 lg4:mt-0"><svg xmlns="http://www.w3.org/2000/svg" className="flex dark:hidden" width="23" height="23" fill="currentColor" viewBox="0 0 16 16"><path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" /><path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z" /></svg> <svg xmlns="http://www.w3.org/2000/svg" className="hidden dark:flex" width="35" height="35" fill="currentColor" viewBox="0 0 16 16"><path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm.5-9.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 11a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm5-5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm-11 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9.743-4.036a.5.5 0 1 1-.707-.707.5.5 0 0 1 .707.707zm-7.779 7.779a.5.5 0 1 1-.707-.707.5.5 0 0 1 .707.707zm7.072 0a.5.5 0 1 1 .707-.707.5.5 0 0 1-.707.707zM3.757 4.464a.5.5 0 1 1 .707-.707.5.5 0 0 1-.707.707z" /></svg></button>
            <button onClick={() => { setnavBar("translate-x-full") }} className="flex lg4:hidden items-center justify-center absolute top-3 right-3"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#8866DD" viewBox="0 0 16 16"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /></svg></button>
          </div>
          <button onClick={() => { setnavBar("translate-x-0") }} className="flex items-center justify-center lg4:hidden"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#8866DD" viewBox="0 0 16 16"><path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" /></svg></button>
        </div>
        <div onClick={() => { setnavBar("translate-x-full") }} className={`flex lg4:hidden fixed top-0 left-0 min-h-screen min-w-full bg-black dark:bg-white dark:bg-opacity-30 bg-opacity-30 transition z-[60] ${navBar === "translate-x-full" ? "opacity-0 invisible" : "opacity-100 visible"}`}></div>
      </div>
    </>
  )
}

export default Header