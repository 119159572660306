import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <>
      {
        id === open ?
          <svg width="15" height="3" className="stroke-[#0F1016] dark:stroke-[#FDFDFD]" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.875H13.7692" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>
          :
          <svg width="15" height="17" className="stroke-[#0F1016] dark:stroke-[#FDFDFD]" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.52222 1.875V15.875" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M1.13727 8.875H13.9065" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>
      }
    </>
  );
}

export function FAQ() {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <>
      <div className="flex flex-col divide-y divide-[#0F1016] dark:divide-[#696969] divide-opacity-10">
        <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
          <AccordionHeader className="text-left text-[20px] font-medium border-b-0 dark:text-[#FDFDFD]" onClick={() => handleOpen(1)}>
            What is Soroswap.Finance?
          </AccordionHeader>
          <AccordionBody className="text-[16px] text-[#4E4E4E] dark:text-[#bebebe] -mt-5">
            The Soroswap.Finance is an open-source protocol for providing liquidity and trading tokens on Soroban. It eliminates trusted intermediaries and unnecessary forms of rent extraction, allowing for safe, accessible, and efficient exchange activity. The protocol is non-upgradable and designed to be censorship resistant.

            The Soroswap.Finance and the Soroswap Interface were developed by Paltalabs.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
          <AccordionHeader className="text-left text-[20px] font-medium border-b-0 dark:text-[#FDFDFD]" onClick={() => handleOpen(2)}>
            How do I use the Soroswap.Finance?          </AccordionHeader>
          <AccordionBody className="text-[16px] text-[#4E4E4E] dark:text-[#bebebe] -mt-5">
            To mint test tokens, create a new liquidity pool, provide liquidity or swap tokens head over to the Soroswap Interface and connect Freighter wallet. Remember, each transaction on Soroban costs Lumens (XLM) so you will need test lumens in order to use it. Ask friendbot for test lumens.
            <br></br>
            <br></br>

            If you're a developer interested in building on top of the Soroswap.Finance, please refer to our extensive <a className="text-main" href="https://docs.soroswap.finance" >docs.</a>
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
          <AccordionHeader className="text-left text-[20px] font-medium border-b-0 dark:text-[#FDFDFD]" onClick={() => handleOpen(3)}>
            How does Soroswap.Finance works?
          </AccordionHeader>
          <AccordionBody className="text-[16px] text-[#4E4E4E] dark:text-[#bebebe] -mt-5">
            Soroswap is an automated market maker. In practical terms, it is a collection of smart contracts that define a standard way to create liquidity pools, provide liquidity, and swap assets.
            <br></br>
            <br></br>
            Each liquidity pool contains two assets. The pools keep track of aggregate liquidity reserves and the pre-defined pricing strategies set by liquidity providers. Reserves and prices are updated automatically every time someone trades. There is no central order book, no third-party custody, and no private order matching engine.
            <br></br>
            <br></br>
            Because reserves are automatically rebalanced after each trade, a Soroswap pool can always be used to buy or sell a token — unlike traditional exchanges, traders do not need to match with individual counterparties to complete a trade.
            <br></br>
            <br></br>
            For a more in-depth description, check out the Concepts from the documentation.
          </AccordionBody>
        </Accordion>
        {/* <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
          <AccordionHeader className="text-left text-[20px] font-medium border-b-0 dark:text-[#FDFDFD]" onClick={() => handleOpen(4)}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</AccordionHeader>
          <AccordionBody className="text-[16px] text-[#4E4E4E] dark:text-[#bebebe] -mt-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore incididunt ut labore labore incididunt ut labore
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
          <AccordionHeader className="text-left text-[20px] font-medium border-b-0 dark:text-[#FDFDFD]" onClick={() => handleOpen(5)}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </AccordionHeader>
          <AccordionBody className="text-[16px] text-[#4E4E4E] dark:text-[#bebebe] -mt-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore incididunt ut labore labore incididunt ut labore
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 6} icon={<Icon id={6} open={open} />}>
          <AccordionHeader className="text-left text-[20px] font-medium border-b-0 dark:text-[#FDFDFD]" onClick={() => handleOpen(6)}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </AccordionHeader>
          <AccordionBody className="text-[16px] text-[#4E4E4E] dark:text-[#bebebe] -mt-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore incididunt ut labore labore incididunt ut labore
          </AccordionBody>
        </Accordion> */}
      </div>
    </>
  );
}